import request from '@/api/request'
// 微信跳转登录
export function LoginAPIUserLoginGetUserLoginByOpenid (params) {
  return request({
    url: '/api/LoginAPI/GetUserLoginByOpenid',
    method: 'get',
    params
  })
}
// 登录
export function LoginAPIUserLogin (data) {
  return request({
    url: '/api/LoginAPI/UserLogin',
    method: 'post',
    data
  })
}
// 客户公司信息
export function PlusCustomerCompanysGetAvailable (params) {
  return request({
    url: '/api/plusCustomerCompanys/getAvailable',
    method: 'get',
    params
  })
}
// 获取全部员工
export function UsersGetAllUsers (params) {
  return request({
    url: '/api/users/getAllUsers',
    method: 'get',
    params
  })
}
// 部门人员树形
export function UsersGetExecutors (params) {
  return request({
    url: '/api/users/getExecutors',
    method: 'get',
    params
  })
}
// 获取主题列表
export function BusinessCommunicationThemesGetList (data) {
  return request({
    url: '/api/BusinessCommunicationThemes/GetList',
    method: 'post',
    data
  })
}
// 新增主题
export function BusinessCommunicationThemesAdd (data) {
  return request({
    url: '/api/BusinessCommunicationThemes/add',
    method: 'post',
    data
  })
}
// 删除主题
export function BusinessCommunicationThemesDel (params) {
  return request({
    url: '/api/BusinessCommunicationThemes/del',
    method: 'get',
    params
  })
}

// 修改主题
export function BusinessCommunicationThemesEdit (data) {
  return request({
    url: '/api/BusinessCommunicationThemes/edit',
    method: 'post',
    data
  })
}

// 获取主题基本信息
export function BusinessCommunicationGet (params) {
  return request({
    url: '/api/BusinessCommunicationThemes/get',
    method: 'get',
    params
  })
}

// 获取主题详情
export function BusinessCommunicationThemesGetDetail (params) {
  return request({
    url: '/api/BusinessCommunicationThemes/getDetail',
    method: 'get',
    params
  })
}

// 客户交流报告详情
export function CustomerCommunicationReportsGet (params) {
  return request({
    url: '/api/CustomerCommunicationReports/get',
    method: 'get',
    params
  })
}

// 获取主题的最后一个报告详情
export function CustomerCommunicationReportsGetLastReport (params) {
  return request({
    url: '/api/CustomerCommunicationReports/getLastReport',
    method: 'get',
    params
  })
}

// 删除交流报告
export function CustomerCommunicationReportsDel (params) {
  return request({
    url: '/api/customerCommunicationReports/del',
    method: 'get',
    params
  })
}

// 保存客户交流报告
export function CustomerCommunicationReportsSave (data) {
  return request({
    url: '/api/CustomerCommunicationReports/save',
    method: 'post',
    data
  })
}

// 获取客户联系人列表
export function CustomerContactsGetList (data) {
  return request({
    url: '/api/CustomerContacts/GetList',
    method: 'post',
    data
  })
}

// 获取客户联系人详情
export function CustomerContactsGetDetail (params) {
  return request({
    url: '/api/customerContacts/getDetail',
    method: 'get',
    params
  })
}

// 添加跟进消息
export function CustomerCommunicationReportsAddComment (data) {
  return request({
    url: '/api/customerCommunicationReports/addComment',
    method: 'post',
    data
  })
}

// 获取跟进消息详情
export function CustomerCommunicationReportsGetComments (params) {
  return request({
    url: '/api/customerCommunicationReports/getComments',
    method: 'get',
    params
  })
}

// 获取跟进选中人员
export function CustomerCommunicationReportsGetCommentChecks (params) {
  return request({
    url: '/api/customerCommunicationReports/getCommentChecks',
    method: 'get',
    params
  })
}

// 删除跟进消息
export function CustomerCommunicationReportsDelComment (params) {
  return request({
    url: '/api/customerCommunicationReports/delComment',
    method: 'get',
    params
  })
}

// 确认已查看跟进消息
export function CustomerCommunicationReportsCheckComment (params) {
  return request({
    url: '/api/customerCommunicationReports/checkComment',
    method: 'get',
    params
  })
}

// 管理后台-获取我的消息
export function InnerMessagesGetMine (data) {
  return request({
    url: '/api/InnerMessages/GetMine',
    method: 'post',
    data
  })
}

// 是否有新消息
export function innerMessagesGetUnreadCount (params) {
  return request({
    url: '/api/innerMessages/getUnreadCount',
    method: 'get',
    params
  })
}

// 消息已读
export function innerMessagesRead (params) {
  return request({
    url: '/api/innerMessages/read',
    method: 'get',
    params
  })
}

// 消息批量已读
export function innerMessagesMultiRead (data) {
  return request({
    url: '/api/innerMessages/multiRead',
    method: 'post',
    data
  })
}

// 获取审核记录的申请类型
export function AuditGetBusinessOptions (params) {
  return request({
    url: '/api/audit/getBusinessOptions',
    method: 'get',
    params
  })
}
// 获取审核记录列表
export function AuditGetRecordsOfMine (data) {
  return request({
    url: '/api/Audit/GetRecordsOfMine',
    method: 'post',
    data
  })
}

// 获取我的申请列表
export function AuditGetMine (data) {
  return request({
    url: '/api/Audit/GetMine',
    method: 'post',
    data
  })
}

// 获取审核记录详情
export function AuditGet (params) {
  return request({
    url: '/api/audit/get/' + params.id,
    method: 'get'
  })
}
export function AuditGetRecordsWithFlow (params) {
  return request({
    url: '/api/audit/getRecordsWithFlow/' + params.id,
    method: 'get'
  })
}
// 审核还款记录
export function borrowingApplicationsGetPaybackRecords (params) {
  return request({
    url: '/api/borrowingApplications/getPaybackRecords/' + params.id,
    method: 'get'
  })
}
// 提交审核
export function auditSubmit (params) {
  return request({
    url: '/api/Audit/submit/' + params.id,
    method: 'get'
  })
}
// 撤回审核提交
export function auditWithdraw (params) {
  return request({
    url: 'api/Audit/withdraw/' + params.id,
    method: 'get'
  })
}

// 待我审核列表
export function auditNeedAuditByMe (data) {
  return request({
    url: '/api/Audit/NeedAuditByMe',
    method: 'post',
    data
  })
}
// 通过申请
export function auditApprove (params) {
  return request({
    url: '/api/Audit/approve/' + params.id,
    method: 'get'
  })
}
// 驳回申请
export function auditReject (data) {
  return request({
    url: '/api/audit/reject',
    method: 'post',
    data
  })
}
