<template>
  <div class="home-details">
    <div class="title">{{ detailsType==='1' ? '差旅报销申请' : detailsType==='3' ? '费用报销申请' : detailsType==='4' ? '借款申请' : '付款申请'}}</div>
    <template v-if="detailsType==='1'">
      <div class="containers">
        <p class="containers-title">基本信息</p>
        <div class="rows" v-if="detailsData.travelDate!==''"><p>出差日期</p><span>{{detailsData.travelDate}}</span></div>
        <div class="rows" v-if="detailsData.departmentName!==''"><p>部门</p><span>{{detailsData.departmentName}}</span></div>
        <div class="rows" v-if="detailsData.applicantName!==''"><p>出差人员</p><span>{{detailsData.applicantName}}</span></div>
        <div class="rows" v-if="detailsData.expenditureTypeName!==''"><p>支出类型</p><span>{{detailsData.expenditureTypeName}}</span></div>
        <div class="rows" v-if="detailsData.projectName!==''"><p>关联项目</p><span>{{detailsData.projectName}}</span></div>
        <div class="rows" v-if="detailsData.description!==''"><p>申请事由</p><span>{{detailsData.description}}</span></div>
        <div class="rows" v-if="detailsData.totalAmount!==''"><p>报销合计</p><span>{{detailsData.totalAmount}}</span></div>
        <div class="rows" v-if="detailsData.paymentMethodName!==''"><p>支付方式</p><span>{{detailsData.paymentMethodName}}</span></div>
        <div class="rows" v-if="detailsData.bankName!==''"><p>开户行</p><span>{{detailsData.bankName}}</span></div>
        <div class="rows" v-if="detailsData.account!==''"><p>账号</p><span>{{detailsData.account}}</span></div>
        <div class="rows" v-if="detailsData.accountName!==''"><p>收款人</p><span>{{detailsData.accountName}}</span></div>
        <div class="rows" v-if="detailsData.note!==''"><p>备注</p><span>{{detailsData.note}}</span></div>
      </div>
      <div class="containers">
        <p class="containers-title">借款冲账</p>
        <div class="rows" v-if="detailsData.deductAmount!==''"><p>冲账金额</p><span>{{detailsData.deductAmount}}</span></div>
      </div>
      <div class="containers" v-if="detailsData.files!==''">
        <p class="containers-title">其他附件</p>
        <!-- <div class="rows" v-for="(item,index) in detailsData.files" :key="index" style="height:auto !important"><p style="width: 80%;padding:0.09rem 0 !important">{{item.name}}</p><a target="_blank" :href="item.url"><van-icon name="down" /></a></div> -->
        <div class="rows" v-for="(item,index) in detailsData.files" :key="index" style="height:auto !important"><p style="width: 80%;padding:0.09rem 0 !important">{{item.name}}</p></div>
      </div>
      <div class="containers">
        <p class="containers-title">审核记录</p>
        <div class="rows h-auto">
          <div class="rows-table">
            <div class="th">
              <div class="td" style="width: 15%;"><div class="td-bg-box"><span>人员</span></div></div>
              <div class="td" style="width: 20%;"><div class="td-bg-box"><span>审核结果</span></div></div>
              <div class="td" style="width: 45%;"><div class="td-bg-box"><span>理由</span></div></div>
              <div class="td" style="width: 20%;"><div class="td-bg-box"><span>审核日期</span></div></div>
            </div>
            <div class="tr" v-for="(item,index) in recsList" :key="index">
              <div class="td" style="width: 15%;"><div class="td-bg-box">{{item.auditorName}}</div></div>
              <div class="td" style="width: 20%;"><div class="td-bg-box">{{item.resultName}}</div></div>
              <div class="td" style="width: 45%;"><div class="td-bg-box">{{item.reason}}</div></div>
              <div class="td" style="width: 20%;"><div class="td-bg-box">{{item.auditTime}}</div></div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="detailsType==='3'">
      <div class="containers">
        <p class="containers-title">基本信息</p>
        <div class="rows" v-if="detailsData.departmentName!==''"><p>部门</p><span>{{detailsData.departmentName}}</span></div>
        <div class="rows" v-if="detailsData.handlerName!==''"><p>经办人</p><span>{{detailsData.handlerName}}</span></div>
        <div class="rows" v-if="detailsData.expectedDate!==''"><p>期望日期</p><span>{{detailsData.expectedDate}}</span></div>
        <div class="rows" v-if="detailsData.projectName!==''"><p>关联项目</p><span>{{detailsData.projectName}}</span></div>
        <div class="rows" v-if="detailsData.description!==''"><p>申请事由</p><span>{{detailsData.description}}</span></div>
      </div>
      <div class="containers">
        <p class="containers-title">报销内容</p>
        <div class="rows" v-if="detailsData.expenditureTypeName!==''"><p>费用类型</p><span>{{detailsData.expenditureTypeName}}</span></div>
        <div class="rows" v-if="detailsData.invoiceTypeName!==''"><p>票据类型</p><span>{{detailsData.invoiceTypeName}}</span></div>
        <div class="rows" v-if="detailsData.amount!==''"><p>开票金额</p><span>{{detailsData.amount}}</span></div>
        <div class="rows" v-if="detailsData.deTaxAmount!==''"><p>可抵税金额</p><span>{{detailsData.deTaxAmount}}</span></div>
        <div class="rows" v-if="detailsData.note!==''"><p>备注</p><span>{{detailsData.note}}</span></div>
      </div>
      <div class="containers">
        <p class="containers-title">合计</p>
        <div class="rows" v-if="detailsData.amount!==''"><p>金额</p><span>{{detailsData.amount}}</span></div>
        <div class="rows" v-if="detailsData.taxAmount!==''"><p>税额</p><span>{{detailsData.taxAmount}}</span></div>
        <div class="rows" v-if="detailsData.paymentMethodName!==''"><p>支付方式</p><span>{{detailsData.paymentMethodName}}</span></div>
        <div class="rows" v-if="detailsData.note!==''"><p>备注</p><span>{{detailsData.note}}</span></div>
      </div>
      <div class="containers">
        <p class="containers-title">借款冲账</p>
        <div class="rows" v-if="detailsData.deductAmount!==''"><p>冲账金额</p><span>{{detailsData.deductAmount}}</span></div>
      </div>
      <div class="containers" v-if="detailsData.files!==''">
        <p class="containers-title">其他附件</p>
        <div class="rows" v-for="(item,index) in detailsData.files" :key="index" style="height:auto !important"><p style="width: 80%;padding:0.09rem 0 !important">{{item.name}}</p></div>
      </div>
      <div class="containers">
        <p class="containers-title">审核记录</p>
        <div class="rows h-auto">
          <div class="rows-table">
            <div class="th">
              <div class="td" style="width: 25%;"><div class="td-bg-box"><span>环节</span></div></div>
              <div class="td" style="width: 15%;"><div class="td-bg-box"><span>人员</span></div></div>
              <div class="td" style="width: 20%;"><div class="td-bg-box"><span>审核结果</span><span>审核日期</span></div></div>
              <div class="td" style="width: 45%;"><div class="td-bg-box"><span>理由</span></div></div>
            </div>
            <div class="tr" v-for="(item,index) in recsList" :key="index">
              <div class="td" style="width: 25%;"><div class="td-bg-box">{{item.nodeName}}</div></div>
              <div class="td" style="width: 15%;"><div class="td-bg-box">{{item.auditorName}}</div></div>
              <div class="td" style="width: 20%;"><div class="td-bg-box"><span>{{item.resultName}}</span><span>{{item.auditTime}}</span></div></div>
              <div class="td" style="width: 45%;"><div class="td-bg-box">{{item.reason}}</div></div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="detailsType==='4'">
      <div class="containers">
        <p class="containers-title">基本信息</p>
        <div class="rows" v-if="detailsData.departmentName!==''"><p>部门</p><span>{{detailsData.departmentName}}</span></div>
        <div class="rows" v-if="detailsData.handlerName!==''"><p>经办人</p><span>{{detailsData.handlerName}}</span></div>
        <div class="rows" v-if="detailsData.expectedDate!==''"><p>期望日期</p><span>{{detailsData.expectedDate}}</span></div>
        <div class="rows" v-if="detailsData.projectName!==''"><p>关联项目</p><span>{{detailsData.projectName}}</span></div>
        <div class="rows" v-if="detailsData.expenditureTypeName!==''"><p>支出类型</p><span>{{detailsData.expenditureTypeName}}</span></div>
        <div class="rows" v-if="detailsData.borrowingTypeName!==''"><p>借款类型</p><span>{{detailsData.borrowingTypeName}}</span></div>
        <div class="rows" v-if="detailsData.expectedPayBack!==''"><p>预计还款</p><span>{{detailsData.expectedPayBack}}</span></div>
        <div class="rows" v-if="detailsData.reason!==''"><p>申请事由</p><span>{{detailsData.reason}}</span></div>
      </div>
      <div class="containers">
        <p class="containers-title">支付</p>
        <div class="rows" v-if="detailsData.amount!==''"><p>金额</p><span>{{detailsData.amount}}</span></div>
        <div class="rows" v-if="detailsData.paymentMethodName!==''"><p>支付方式</p><span>{{detailsData.paymentMethodName}}</span></div>
        <div class="rows" v-if="detailsData.paymentStateName!==''"><p>支付状态</p><span>{{detailsData.paymentStateName}}</span></div>
        <div class="rows" v-if="detailsData.bankName!==''"><p>开户行</p><span>{{detailsData.bankName}}</span></div>
        <div class="rows" v-if="detailsData.account!==''"><p>账号</p><span>{{detailsData.account}}</span></div>
        <div class="rows" v-if="detailsData.accountName!==''"><p>收款人</p><span>{{detailsData.accountName}}</span></div>
        <div class="rows" v-if="detailsData.note!==''"><p>备注</p><span>{{detailsData.note}}</span></div>
      </div>
      <div class="containers" v-if="detailsData.files!==''">
        <p class="containers-title">其他附件</p>
        <div class="rows" v-for="(item,index) in detailsData.files" :key="index" style="height:auto !important"><p style="width: 80%;padding:0.09rem 0 !important">{{item.name}}</p></div>
      </div>
      <div class="containers">
        <p class="containers-title">审核记录</p>
        <div class="rows h-auto">
          <div class="rows-table">
            <div class="th">
              <div class="td" style="width: 25%;"><div class="td-bg-box"><span>环节</span></div></div>
              <div class="td" style="width: 15%;"><div class="td-bg-box"><span>人员</span></div></div>
              <div class="td" style="width: 20%;"><div class="td-bg-box"><span>审核结果</span><span>审核日期</span></div></div>
              <div class="td" style="width: 45%;"><div class="td-bg-box"><span>理由</span></div></div>
            </div>
            <div class="tr" v-for="(item,index) in recsList" :key="index">
              <div class="td" style="width: 25%;"><div class="td-bg-box">{{item.nodeName}}</div></div>
              <div class="td" style="width: 15%;"><div class="td-bg-box">{{item.auditorName}}</div></div>
              <div class="td" style="width: 20%;"><div class="td-bg-box"><span>{{item.resultName}}</span><span>{{item.auditTime}}</span></div></div>
              <div class="td" style="width: 45%;"><div class="td-bg-box">{{item.reason}}</div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="containers">
        <p class="containers-title">还款记录</p>
        <div class="rows h-auto">
          <div class="rows-table">
            <div class="th">
              <div class="td" style="width: 25%;"><div class="td-bg-box"><span>还款金额</span></div></div>
              <div class="td" style="width: 50%;"><div class="td-bg-box"><span>备注</span></div></div>
              <div class="td" style="width: 25%;"><div class="td-bg-box"><span>还款日期</span></div></div>
            </div>
            <div class="tr" v-for="(item,index) in paybackRecords" :key="index">
              <div class="td" style="width: 25%;"><div class="td-bg-box">{{item.amount}}</div></div>
              <div class="td" style="width: 50%;"><div class="td-bg-box">{{item.note}}</div></div>
              <div class="td" style="width: 25%;"><div class="td-bg-box">{{item.updateTime}}</div></div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="detailsType==='5'">
      <div class="containers">
        <p class="containers-title">基本信息</p>
        <div class="rows" v-if="detailsData.departmentName!==''"><p>部门</p><span>{{detailsData.departmentName}}</span></div>
        <div class="rows" v-if="detailsData.handlerName!==''"><p>经办人</p><span>{{detailsData.handlerName}}</span></div>
        <div class="rows" v-if="detailsData.projectName!==''"><p>关联项目</p><span>{{detailsData.projectName}}</span></div>
        <div class="rows" v-if="detailsData.expenditureTypeName!==''"><p>支出类型</p><span>{{detailsData.expenditureTypeName}}</span></div>
        <div class="rows" v-if="detailsData.paymentTypeName!==''"><p>付款类型</p><span>{{detailsData.paymentTypeName}}</span></div>
        <div class="rows" v-if="detailsData.reason!==''"><p>申请事由</p><span>{{detailsData.reason}}</span></div>
      </div>
      <div class="containers">
        <p class="containers-title">支付</p>
        <div class="rows" v-if="detailsData.amount!==''"><p>金额</p><span>{{detailsData.amount}}</span></div>
        <div class="rows" v-if="detailsData.deTaxAmount!==''"><p>可抵税额</p><span>{{detailsData.deTaxAmount}}</span></div>
        <div class="rows" v-if="detailsData.paymentMethodName!==''"><p>支付方式</p><span>{{detailsData.paymentMethodName}}</span></div>
        <div class="rows" v-if="detailsData.paymentStateName!==''"><p>支付状态</p><span>{{detailsData.paymentStateName}}</span></div>
        <div class="rows" v-if="detailsData.bankName!==''"><p>开户行</p><span>{{detailsData.bankName}}</span></div>
        <div class="rows" v-if="detailsData.account!==''"><p>账号</p><span>{{detailsData.account}}</span></div>
        <div class="rows" v-if="detailsData.accountName!==''"><p>收款人</p><span>{{detailsData.accountName}}</span></div>
        <div class="rows" v-if="detailsData.note!==''"><p>备注</p><span>{{detailsData.note}}</span></div>
      </div>
      <div class="containers" v-if="detailsData.picfiles!==''">
        <p class="containers-title">发票拍照</p>
        <div class="rows" v-for="(item,index) in detailsData.picfiles" :key="index" style="height:auto !important"><img :src="item.url" alt=""></div>
      </div>
      <div class="containers" v-if="detailsData.files!==''">
        <p class="containers-title">其他附件</p>
        <div class="rows" v-for="(item,index) in detailsData.files" :key="index" style="height:auto !important"><p style="width: 80%;padding:0.09rem 0 !important">{{item.name}}</p></div>
      </div>
      <div class="containers">
        <p class="containers-title">审核记录</p>
        <div class="rows h-auto">
          <div class="rows-table">
            <div class="th">
              <div class="td" style="width: 25%;"><div class="td-bg-box"><span>环节</span></div></div>
              <div class="td" style="width: 15%;"><div class="td-bg-box"><span>人员</span></div></div>
              <div class="td" style="width: 20%;"><div class="td-bg-box"><span>审核结果</span><span>审核日期</span></div></div>
              <div class="td" style="width: 45%;"><div class="td-bg-box"><span>理由</span></div></div>
            </div>
            <div class="tr" v-for="(item,index) in recsList" :key="index">
              <div class="td" style="width: 25%;"><div class="td-bg-box">{{item.nodeName}}</div></div>
              <div class="td" style="width: 15%;"><div class="td-bg-box">{{item.auditorName}}</div></div>
              <div class="td" style="width: 20%;"><div class="td-bg-box"><span>{{item.resultName}}</span><span>{{item.auditTime}}</span></div></div>
              <div class="td" style="width: 45%;"><div class="td-bg-box">{{item.reason}}</div></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import {
  AuditGet,
  AuditGetRecordsWithFlow,
  borrowingApplicationsGetPaybackRecords
} from '@/api/api'
const route = useRoute()
// 页面信息
const detailsData = ref({})
// 审核类型
const detailsType = ref(route.query.type)
// 还款记录
const paybackRecords = ref([])
// 审核流程
const recsList = ref([])
// 获取主题详情
onMounted(() => {
  getDetails()
})
const getDetails = () => {
  const params = {
    id: `${route.query.type}-${route.query.apply}`
  }
  const params1 = {
    id: `${route.query.apply}`
  }
  AuditGet(params).then(res => {
    detailsData.value = res.data.data
    detailsData.value.totalAmount = (res.data.data.items !== undefined) ? res.data.data.items.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0) : ''
    detailsData.value.deductAmount = (res.data.data.deducts !== undefined) ? res.data.data.deducts.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0) : ''
    filesChange()
  })
  AuditGetRecordsWithFlow(params).then(res => {
    // 取“结果”列应当显示的内容
    const getResultText = (item) => {
      let res = item.resultName
      if (item.reason.indexOf('最终') > -1) {
        return item.reason
      }
      switch (item.result) {
        case 2: // 驳回
          res = `${item.resultName}（${item.reason}）`
          break
        case 3: // 待定
          res = item.reason
          break
        default:
          break
      }
      return res
    }
    // 先拆出来
    const nodes = res.data.data.flow
    let recs = res.data.data.records
    // 找到最后一次提交的时间
    const lastSubmitTime = (recs !== undefined)
      ? ''
      : recs.filter((x) => x.reason === '提交审核')
        .sort((a, b) => {
          return a.auditTime > b.auditTime ? -1 : 1
        })[0].auditTime

    // 删掉最后一次提交之前的所有记录
    recs = recs.filter((x) => x.auditTime >= lastSubmitTime)
    // 审核大节点，先排序
    const newNodes = []
    let next = nodes.find((x) => x.pre === 0)
    while (next) {
      newNodes.push(next)
      next = nodes.find((x) => x.pre === next.id)
    }
    // 以节点为基础，结合记录，构建呈现的数据
    const newRecs = []
    const extract = (node, name) => {
      // 如果当前是有各种分支的，取分支，这边不要
      if (node.lines !== null) {
        for (const subLine of node.lines) {
          subLine.map((x) => extract(x, name !== '' ? node.name : name))
        }
      } else {
        newRecs.push({
          nodeName: name,
          auditorName: node.specificAuditors[0].name, // 只取第一个，需求说，会在流程使用上，保证只有一个，如果不对，是使用的问题
          auditor: node.specificAuditors[0].id,
          auditTime: '',
          resultText: '',
          reason: '',
          resultName: ''
        })
      }
    }
    // 按节点顺序解开
    for (const node of newNodes) {
      extract(node)
    }
    // 每个人，检查一遍有没有审核过，结果是什么
    for (const step of newRecs) {
      // 尝试找审核记录里面对应的人
      const rec = recs.find((x) => x.auditor === step.auditor)
      // 有记录，就赋值
      if (rec) {
        step.reason = rec.reason
        step.resultName = rec.resultName
        step.auditTime = rec.auditTime
        step.resultText = getResultText(rec)

        // 如果审核记录，是申请人自己的提交记录
        if (rec.applicant === rec.auditor) {
          step.resultText = '跳过（申请人自己）'
          step.resultName = '跳过'
          step.reason = '申请人自己'
        }
      }
    }
    recsList.value = newRecs
  })
  borrowingApplicationsGetPaybackRecords(params1).then(res => {
    if (res.data.data.length > 0) {
      paybackRecords.value = res.data.data
    } else {
      paybackRecords.value = '无'
    }
  })
}
const filesChange = () => {
  const list = detailsData.value.files
  const x1 = []
  // eslint-disable-next-line array-callback-return
  list.map(x => {
    x1.push({ path: x.path })
  })
  // eslint-disable-next-line no-undef
  cit.use('oss-file').then(ossFile => {
    ossFile.download.updateUrl(x1).then(files => {
      console.log(files)
      for (const mod1 of files) {
        for (let i = 0; i < list.length; i++) {
          if (mod1.path === list[i].xd) {
            list[i].url = mod1.url
            detailsData.value.files = list.filter(r => r.isPic === false)
            detailsData.value.picfiles = list.filter(r => r.isPic)
            break
          }
        }
      }
    })
  })
}
</script>
<style lang="scss" scoped>
  .home-details{
    width: 100%;
    height: 100vh;
    background:#fff;
    .title{
      width: 100%;
      font-size: 0.17rem;
      font-weight: bold;
      line-height: 0.4rem;
      text-align: center;
      color:#333;
    }
    .containers{
      width: 100%;
      padding:0 0.15rem 0.15rem 0.15rem;
      box-sizing: border-box;
      .containers-title{
        font-size: 0.14rem;
        font-weight: bold;
        line-height: 0.26rem;
        padding-left: 0.09rem;
        color: darkslategrey;
        position: relative;
        &::after{
          content: "";
          width: 0.03rem;
          height: 0.14rem;
          background: #009688;
          position: absolute;
          left: 0.01rem;
          top: 0.06rem;
        }
      }
      .h-auto{
        height:auto !important;
      }
      .rows{
        width: 100%;
        min-height: 0.32rem;
        padding: 0.06rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        p{
          font-size: 0.14rem;
          color:#666;
        }
        span{
          font-size: 0.14rem;
          color:#333;
          text-align: end;
          line-height: 0.22rem;
        }
        a{
          font-size: 0.16rem;
          font-weight: bold;
          color:#009688
        }
        img{
          width: 0.8rem;
          height:auto
        }
        .rows-table{
          width: 100%;
          .th,.tr{
            width: 100%;
            min-height: 0.32rem;
            line-height: 0.26rem;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            justify-content: space-between;
            border: 0.01rem solid #e6e6e6;
            border-bottom:0;
            background: #f2f2f2;
            box-sizing: border-box;
          }
          .tr{
            background:none;
            height: auto;
          }
          .td{
            height:auto;
            color:#666;
            border-right:0.01rem solid #e6e6e6;
            font-size: 0.12rem;
            line-height: 0.2rem;
            .td-bg-box{
              text-align: center;
              width:100%;
              height:100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            }
            span{
              width: 100%;
              display: block;
              color:#666;
              font-size: 0.12rem;
              line-height: 0.2rem;
            }
            &:last-of-type{
              border:0;
            }
          }
        }
      }
    }
  }
</style>
