<template>
  <div class="home">
    <div class="searchbox">
      <div class="inputbox">
        <div class="input-minmax">
          <van-field
            v-model="themeKeyword"
            clearable
            clear-trigger="always"
            name="themeKeyword"
            autocomplete="off"
            placeholder="关键字"
            class="minmax-style"
            @clear="search()"
          />
          <van-field
            v-model="dateStr"
            clearable
            clear-trigger="always"
            readonly
            :right-icon="dateStr ? 'clear' : null"
            @click-right-icon.stop="clearContent()"
            name="dateStr"
            autocomplete="off"
            placeholder="审核日期"
            class="minmax-style"
            @click="showDatePopup"
            @clear="search()"
          />
        </div>
        <div class="input-minmax">
          <van-field
            v-model="min" type="digit"
            clearable
            clear-trigger="always"
            name="min"
            autocomplete="off"
            placeholder="金额最小"
            class="minmax-style"
            @clear="search()"
          />
          <p>-</p>
          <van-field
            v-model="max" type="digit"
            clearable
            clear-trigger="always"
            name="max"
            autocomplete="off"
            placeholder="金额最大"
            class="minmax-style"
            @clear="search()"
          />
        </div>
        <div class="input-minmax">
          <van-field
            v-model="applicationType"
            name="applicationType"
            readonly
            :right-icon="applicationType ? 'clear' : null"
            @click-right-icon.stop="clearContent('applicationType')"
            autocomplete="off"
            placeholder="申请类型"
            class="minmax-style"
            @click="showApplicationType"
          />
          <van-field
            autocomplete="off"
            v-model="treeUsers"
            readonly
            placeholder="申请人"
            class="minmax-style"
            rows="2"
            autosize
            @click="showPickerTree()"
          />
        </div>
        <!-- <input type="text" v-model="dateStr" readonly placeholder="报告日期" @click="showDatePopup"/> -->
      </div>
      <button class="btn" @click="search">搜索</button>
    </div>
    <div class="listbox">
      <div class="listarr">
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="list" v-for="(item,index) in listData" :key="index" @click="pagePush(item.type,item.apply)">
            <div class="title" v-if="item.typeName!==''"><p>{{item.typeName}}{{item.amount!==''?`【${item.amount}元】`:''}}</p></div>
            <div class="listitem" v-if="item.description!==''"><p>申请事由：{{item.description}}</p></div>
            <div class="listitem" v-if="item.applicantName!==''"><p>申请人：{{item.applicantName}}</p></div>
            <div class="listitem" v-if="item.result!==''"><p>审核结果：<span>{{item.result == 1 ? '通过' : item.result == 2 ? '不通过' : '-'}}</span></p></div>
            <div class="listitem" v-if="item.reason!==''"><p>说明：{{item.reason}}</p></div>
            <div class="listitem" v-if="item.auditTime!==''"><p>审核日期：{{item.auditTime}}</p></div>
            <div class="btn-rows">
              <button class="degree-btn" @click.stop="degree(item.type,item.apply)">申请审核记录</button>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <!-- 日历 -->
    <van-calendar v-model:show="dateshow" type="range" :min-date="minDate" :max-date="maxDate" :allow-same-day="true" :show-confirm="false" @confirm="onConfirm" />
    <!-- 员工选择 -->
    <van-popup v-model:show="showPicker" position="bottom">
    <!-- <van-popup v-model:show="showPicker" position="bottom" @close="showToasts('close')"> -->
      <div class="toolbox-btn">
        <div class="cancel" @click="showPicker=false">取消</div>
        <div class="confirm" @click="showToasts()">确认</div>
      </div>
      <usersTree ref="treeData" :propsTreeDatas="propsTreeDatas" :allChecked="allChecked" :listIndex="listIndex" :key="forceRenderKey"></usersTree>
    </van-popup>
    <!-- 申请类型 -->
    <van-popup v-model:show="showPicker1" position="bottom" @close="showPicker1=false">
      <div class="toolbox-btn">
        <div class="cancel" @click="showPicker1=false">取消</div>
        <div class="confirm" @click="showToast1()">确认</div>
      </div>
      <checkedSelect :datatype="datatype" :checkedApplicationType="checkedApplicationType" ref="atData" :key="checkedSelectIndex"></checkedSelect>
    </van-popup>
    <!-- 审核记录 -->
    <van-popup v-model:show="showPicker2">
    <!-- <van-popup v-model:show="showPicker" position="bottom" @close="showToasts('close')"> -->
      <div class="toolbox-btn">
        <div class="cancel" @click="showPicker2=false">取消</div>
        <div class="confirm" @click="showToast2()">确认</div>
      </div>
      <div class="toolbox-table">
        <div class="th">
          <div class="td" style="width: 40%;"><div class="td-bg-box"><span>环节</span></div></div>
          <div class="td" style="width: 20%;"><div class="td-bg-box"><span>结果</span></div></div>
          <div class="td" style="width: 15%;"><div class="td-bg-box"><span>人员</span></div></div>
          <div class="td" style="width: 25%;"><div class="td-bg-box"><span>时间</span></div></div>
        </div>
        <div class="tr" v-for="(item,index) in recsList" :key="index">
          <div class="td" style="width: 40%;"><div class="td-bg-box">{{item.nodeName}}</div></div>
          <div class="td" style="width: 20%;"><div class="td-bg-box">{{item.resultName}}</div></div>
          <div class="td" style="width: 15%;"><div class="td-bg-box">{{item.auditorName}}</div></div>
          <div class="td" style="width: 25%;"><div class="td-bg-box">{{item.auditTime}}</div></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>

import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import {
  AuditGetRecordsOfMine,
  AuditGetRecordsWithFlow
} from '@/api/api'
import { showToast } from 'vant'
const router = useRouter()
const themeKeyword = ref('')
const dateStr = ref('')
const startDate = ref('')
const endDate = ref('')
const dateshow = ref(false)
const showPicker = ref(false)
const showPicker1 = ref(false)
const showPicker2 = ref(false)
const datatype = ref('')
const applicationTypeId = ref('')
const applicationType = ref('')
const checkedApplicationType = ref([])
const atData = ref(null) // 申请类型组件传值
const checkedSelectIndex = ref(0)
const min = ref('')
const max = ref('')
// 树形
const treeData = ref({})
const propsTreeDatas = ref([])
const allChecked = ref([])
const forceRenderKey = ref(0)
const treeUsers = ref('')
const treeUsersArr = ref([])
const treeUsersIds = ref([])

const listData = ref([])
const minDate = new Date(new Date().getFullYear() - 2, new Date().getMonth() + 1, new Date().getDate())
const maxDate = new Date(new Date().getFullYear() + 1, new Date().getMonth() + 1, new Date().getDate())
const pageIndex = ref(1)
const pageSize = ref(10)
// 审核流程
const recsList = ref([])

const loading = ref(false)
const finished = ref(true)
// 获取列表
onMounted(() => {
  listdata()
})
// 触底
const onLoad = () => {
  // console.log('触底')
  loading.value = true
  pageIndex.value++
  listdata()
}
// 搜索
const search = () => {
  // console.log('搜索')
  pageIndex.value = 1
  listData.value = []
  listdata()
}
// 请求列表数据
const listdata = () => {
  loading.value = false
  finished.value = false
  const data = {
    condition: {
      keyword: themeKeyword.value,
      startDate: startDate.value,
      endDate: endDate.value,
      types: applicationType.value
    },
    pager: {
      index: pageIndex.value,
      size: pageSize.value,
      sortField: '',
      sortType: 'DESC',
      sort: ''
    }
  }

  AuditGetRecordsOfMine(data).then((res) => {
    if (res.data.isSuccess) {
      const totalPage = Math.ceil(res.data.dataCount / pageSize.value)
      const listArr = listData.value
      listArr.push(...res.data.data)
      listData.value = listArr
      loading.value = false
      if (totalPage < pageIndex.value || totalPage === pageIndex.value) {
        finished.value = true
      } else {
        finished.value = false
      }
    } else {
      showToast(res.data.message)
    }
  })
}
// 审核记录
const degree = (type, apply) => {
  showPicker2.value = true
  const params = {
    id: `${type}-${apply}`
  }
  AuditGetRecordsWithFlow(params).then(res => {
    // 取“结果”列应当显示的内容
    const getResultText = (item) => {
      let res = item.resultName
      if (item.reason.indexOf('最终') > -1) {
        return item.reason
      }
      switch (item.result) {
        case 2: // 驳回
          res = `${item.resultName}（${item.reason}）`
          break
        case 3: // 待定
          res = item.reason
          break
        default:
          break
      }
      return res
    }
    // 先拆出来
    const nodes = res.data.data.flow
    let recs = res.data.data.records
    // 找到最后一次提交的时间
    const lastSubmitTime = (recs !== undefined)
      ? ''
      : recs.filter((x) => x.reason === '提交审核')
        .sort((a, b) => {
          return a.auditTime > b.auditTime ? -1 : 1
        })[0].auditTime

    // 删掉最后一次提交之前的所有记录
    recs = recs.filter((x) => x.auditTime >= lastSubmitTime)
    // 审核大节点，先排序
    const newNodes = []
    let next = nodes.find((x) => x.pre === 0)
    while (next) {
      newNodes.push(next)
      next = nodes.find((x) => x.pre === next.id)
    }
    // 以节点为基础，结合记录，构建呈现的数据
    const newRecs = []
    const extract = (node, name) => {
      // 如果当前是有各种分支的，取分支，这边不要
      if (node.lines !== null) {
        for (const subLine of node.lines) {
          subLine.map((x) => extract(x, name !== '' ? node.name : name))
        }
      } else {
        newRecs.push({
          nodeName: name,
          auditorName: node.specificAuditors[0].name, // 只取第一个，需求说，会在流程使用上，保证只有一个，如果不对，是使用的问题
          auditor: node.specificAuditors[0].id,
          auditTime: '',
          resultText: '',
          reason: '',
          resultName: ''
        })
      }
    }
    // 按节点顺序解开
    for (const node of newNodes) {
      extract(node)
    }
    // 每个人，检查一遍有没有审核过，结果是什么
    for (const step of newRecs) {
      // 尝试找审核记录里面对应的人
      const rec = recs.find((x) => x.auditor === step.auditor)
      // 有记录，就赋值
      if (rec) {
        step.reason = rec.reason
        step.resultName = rec.resultName
        step.auditTime = rec.auditTime
        step.resultText = getResultText(rec)

        // 如果审核记录，是申请人自己的提交记录
        if (rec.applicant === rec.auditor) {
          step.resultText = '跳过（申请人自己）'
          step.resultName = '跳过'
          step.reason = '申请人自己'
        }
      }
    }
    recsList.value = newRecs
  })
}

// popup展示
const showDatePopup = () => {
  dateshow.value = true
}
const showApplicationType = () => {
  showPicker1.value = true
  datatype.value = 3
  checkedApplicationType.value = applicationTypeId.value.split(',').map((str) => {
    return parseInt(str)
  })
  console.log(applicationTypeId.value)
  checkedSelectIndex.value = checkedSelectIndex.value + 1
}
const showToast1 = () => {
  showPicker1.value = false
  applicationType.value = atData.value.checkedApplicationTypeString
  applicationTypeId.value = atData.value.checkedApplicationTypeListValue
}
const showToast2 = () => {
  showPicker2.value = false
}
// 树形popup
const showToasts = () => {
  showPicker.value = false
  // 有内容才执行
  if (treeData.value.dataobj.name !== undefined) {
    treeUsers.value = treeData.value.dataobj.name.toString()
    treeUsersArr.value = treeData.value.dataobj.name
    treeUsersIds.value = treeData.value.dataobj.id
    // 所有选中的内容回传
    // allChecked.value = formList.value
  }
}
const showPickerTree = () => {
  showPicker.value = true
  // 每次点击后刷新内容
  forceRenderKey.value += 1
  // 已选中的项 回传子组件
  if (treeUsersIds.value.length === 0) {
    propsTreeDatas.value = []
  } else {
    propsTreeDatas.value = treeUsersIds.value.map(item => `id_${item}`)
  }
}
// 日期选择
const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
const onConfirm = (values) => {
  const [start, end] = values
  dateshow.value = false
  dateStr.value = `${formatDate(start)}至${formatDate(end)}`
  startDate.value = `${formatDate(start)}`
  endDate.value = `${formatDate(end)}`
}
// 跳转
const pagePush = (type, apply) => {
  router.push({
    path: '/auditDetails',
    query: { type, apply }
  })
}
// 清除内容
const clearContent = (refname) => {
  if (refname === 'applicationType') {
    applicationType.value = ''
    applicationTypeId.value = ''
  }
  dateStr.value = ''
  startDate.value = ''
  endDate.value = ''
  search()
}
</script>
<style lang="scss" scoped>
  .input-minmax{
    width: 100%;
    height: 0.4rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .minmax-style{
      width:48.5% !important;
    }
    & > p{
      font-size: 0.14rem;
      line-height: 0.32rem;
    }
  }
  .home{
    width: 100%;
    height: calc(100vh - 0.4rem);
    background:#ebebeb;
    .searchbox{
      width: 100%;
      height: 1.75rem;
      background: #fff;
      border-bottom: 0.01rem solid #e6e6e6;
      padding: 0.1rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .inputbox{
        width: 100%;
        height: calc(100% - 0.32rem);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &:nth-of-type(2){
          margin-top: 0.1rem;
        }
        .van-cell::v-deep{
          padding:0 !important;
          &:after{
            border:0;
          }
          .van-field__body{
            height: 0.32rem;
            width: 100%;
            border:0.01rem solid #e6e6e6;
            box-sizing: border-box;
            border-radius: 0.05rem;
            padding: 0 0.1rem;
            input{
              font-size: 0.12rem;
            }
          }
          .van-field__right-icon{
            padding:0 3px;
          }
        }
      }
      .btn{
        height: 0.32rem;
        width: 100%;
        background: #009688;
        color: #fff;
        border: none;
        border-radius: 0.05rem;
        margin-left: 0 !important;
        font-size: 0.15rem;
      }
    }
    .listbox{
      width: 100%;
      height: calc(100vh - 1.95rem - 0.4rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0.1rem;
      box-sizing: border-box;
      .listarr{
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .van-list{
          width: 100%;
        }
        .list{
          width: 100%;
          height: auto;
          background:#fff;
          border-radius: 0.1rem;
          border:0.01rem solid #e6e6e6;
          padding: 0.1rem;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-bottom:0.1rem;
          .title{
            width: 100%;
            height: auto;
            margin-bottom: 0.08rem;
            p{
              font-size: 0.18rem;
              font-weight: bold;
              line-height: 0.24rem;
              text-align: justify;
              color:#333;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
            }
          }
          .listitem{
            width: 100%;
            height: auto;
            p{
              font-size: 0.13rem;
              line-height: 0.2rem;
              text-align: justify;
              color:#333;
            }
          }
          .btn-rows{
            width: 100%;
            height: 0.3rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 0.1rem;
            box-sizing: border-box;
            .degree-btn{
              width: auto;
              height: 0.3rem;
              background: none;
              color: #009688;
              border: none;
              border-radius: 0.04rem;
              font-size: 0.15rem;
              font-weight: bold;
            }
          }
        }
      }
      .adda{
        width: 2.6rem;
        height: 0.35rem;
        display: flex;
        margin-top: 0.14rem;
        .addbtn{
          width: 100%;
          height: 100%;
          background: #009688;
          color: #fff;
          border: none;
          border-radius: 0.04rem;
          font-size: 0.15rem;
        }
      }
    }
  }
  .van-dialog__message{
    font-size: 0.17rem !important;
  }
  .van-button--large{
    font-size: 0.17rem !important;
  }
  .toolbox-table{
    width: 90vw;
    min-height:40vh;
    .th,.tr{
      width: 100%;
      min-height: 0.32rem;
      line-height: 0.26rem;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      border: 0.01rem solid #e6e6e6;
      border-top:0;
      background: #f2f2f2;
      box-sizing: border-box;
    }
    .th{
      border-bottom:0;
    }
    .tr{
      background:none;
      height: auto;
    }
    .td{
      height:auto;
      text-align: center;
      min-height:0.32rem;
      color:#666;
      border-right:0.01rem solid #e6e6e6;
      font-size: 0.12rem;
      line-height: 0.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span{
        width: 100%;
        display: block;
        color:#666;
        font-size: 0.12rem;
        line-height: 0.2rem;
      }
    }
  }
</style>
