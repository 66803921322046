<template>
  <div class="cbody">
    <a-tree
      v-if="treeData.length > 0"
      checkable
      :default-expand-all="defaultExpandAll"
      v-model:defaultExpandedKeys="expandedKeys"
      v-model:selectedKeys="selectedKeys"
      v-model:checkedKeys="checkedKeys"
      :autoExpandParent="autoExpandParent"
      @expand="onExpand"
      @select="onSelect"
      @check="onCheck"
    >
      <a-tree-node v-for="item in treeData" :title="item.name" :key="item.id" :id="item.id" class="parents-class">
        <a-tree-node v-for="child in item.children" :title="child.name" :key="child.id" :id="child.id" class="children-class"></a-tree-node>
      </a-tree-node>
    </a-tree>
  </div>
</template>

<script setup>
import { ref, defineExpose, onMounted, defineProps } from 'vue'
import allUsersTreeData from '../assets/allUsersToTree.json'
const props = defineProps({
  propsTreeDatas: {
    type: Array,
    default: () => []
  },
  allChecked: {
    type: Array,
    default: () => []
  }
})
// 点击选中节点的id
const checkedKeys = ref(props.propsTreeDatas)
// 展开指定的树节点
const expandedKeys = ref([])
// 已经加载的节点
const selectedKeys = ref([])
// 是否自动展开父节点
const autoExpandParent = ref(true)
const treeData = ref([])
const dataobj = ref({})
const defaultExpandAll = ref(true)

// 初始渲染后
onMounted(() => {
  treeData.value = convertToTree(allUsersTreeData.data)
  console.log(checkedKeys.value)
})
// 扁平对象转树形
const convertToTree = (flatData) => {
  flatData.forEach(i => {
    i.key = i.id
    i.title = i.name
  })
  const treeData = []
  const map = new Map()
  let outputObj, parentId
  for (let i = 0; i < flatData.length; i++) {
    parentId = flatData[i].parentId
    if (map.has(parentId)) {
      if (!map.get(parentId).children) { map.get(parentId).children = [] }
      const obj = { ...flatData[i] }
      map.get(parentId).children.push(obj)
      map.set(flatData[i].id, obj)
    } else if (!map.has(parentId) && parentId === 0) {
      outputObj = { ...flatData[i] }
      treeData.push(outputObj)
      map.set(flatData[i].id, outputObj)
    }
  }
  return treeData
}
// 树形展开
const onExpand = (expandedKeys) => {
  // console.log('onExpand', expandedKeys)
  expandedKeys.value = expandedKeys
  autoExpandParent.value = false
}
// 树形点击
const onCheck = (checkedKeys) => {
  // console.log('onCheck', checkedKeys)
  checkedKeys.value = checkedKeys
  // idArr 单次操作所有被选中除父级id数组
  const idArr = checkedKeys.filter(item => typeof item === 'string')
  // allArr 单次操作选中id对应内容
  const allArr = allUsersTreeData.data.filter(item => idArr.find(i => i === item.id))
  // newArr 单次操作选择去重
  const newArr = [...new Set(allArr.map(item => item.id))].map(id => allArr.find(item => item.id === id))
  // 去掉'id_'
  dataobj.value = { id: newArr.map(item => item.id.replace('id_', '')), name: newArr.map(item => item.name) }
}
const onSelect = (selectedKeys, info) => {
  console.log('onSelect', info)
  let arr = checkedKeys.value
  // 如果未选中
  if (info.node.selected) {
    arr = arr.filter(item => item !== info.node.id)
    // 如果有子级
    if (info.node.children) {
      const array2 = info.node.children.map(item => item.id)
      arr = arr.filter((item, index) => !array2.includes(item))
    }
  } else {
    if (info.node.children) {
      arr = [...selectedKeys, ...arr, ...info.node.children.map(item => item.id)]
    } else {
      arr = [...selectedKeys, ...arr]
    }
  }
  arr = [...new Set(arr)] // 去重
  selectedKeys.value = arr
  checkedKeys.value = arr
  onCheck(arr)
  // checkedKeys.value = selectedKeys
}
// 对外暴露
defineExpose({
  dataobj
})

</script>
<style lang="scss">
  .cbody{
    width: 100%;
    height: 80vh;
    background:#ebebeb;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .ant-tree{
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #fff;
    .parents-class{
      width: 100% !important;
    }
    .children-class{
      .ant-tree-switcher{
        width: 0.17rem !important;
      }
      .ant-tree-checkbox + span{
        width: 0.65rem;
        font-size: 0.13rem;
      }
      .ant-tree-switcher-noop{
        width: 0.3rem !important;
      }
    }
    .ant-tree-checkbox + span{
      min-height: 0.3rem !important;
      line-height: 0.3rem !important;
    }
    .ant-tree-indent{
      width: 0 !important;
    }
    .ant-tree-switcher{
      width: 0.3rem !important;
      line-height: 0.3rem !important;
    }
    .ant-tree-list-holder-inner{
      flex-direction: unset !important;
      flex-wrap: wrap;
      .ant-tree-switcher{
        width: 0.36rem;
        line-height: 0.36rem;
        .ant-tree-switcher-icon{
          font-size: 0.14rem;
        }
      }
      .ant-tree-checkbox{
        margin-block-start:-0.02rem;
        .ant-tree-checkbox-inner{
          width: 0.18rem;
          height: 0.18rem;
        }
      }
      .ant-tree-checkbox+span{
        min-height: 0.36rem;
        line-height: 0.36rem;
      }
    }
    .ant-tree-node-selected{
      background: none !important;
    }
    .ant-tree-checkbox+span:hover{
      background: none !important;
    }
  }
</style>
