import { createRouter, createWebHashHistory } from 'vue-router'
import BCTlist from '../views/BCTlist.vue'
import addBCT from '../views/addBCT.vue'
import BCTlistDetails from '../views/BCTlistDetails.vue'
import addBCTReport from '../views/addBCTReport.vue'
import bindOpenIdPage from '../views/bindOpenIdPage.vue'
import msgList from '../views/msgList.vue'
import auditHistory from '../views/auditHistory.vue'
import auditDetails from '../views/auditDetails.vue'
import auditList from '../views/auditList.vue'
import toAudit from '../views/toAudit.vue'

const routes = [
  {
    path: '/BusinessCommunicationThemes',
    name: 'home',
    component: BCTlist,
    meta: {
      title: '客户沟通主题列表'
    }
  },
  {
    path: '/addBCT',
    name: 'addBCT',
    component: addBCT,
    meta: {
      title: '新增主题'
    }
  },
  {
    path: '/BCTlistDetails',
    name: 'BCTlistDetails',
    component: BCTlistDetails,
    meta: {
      title: '沟通主题详情'
    }
  },
  {
    path: '/addBCTReport',
    name: 'addBCTReport',
    component: addBCTReport,
    meta: {
      title: '新增报告编辑报告'
    }
  },
  {
    path: '/bindOpenIdPage',
    name: 'bindOpenIdPage',
    component: bindOpenIdPage,
    meta: {
      title: '绑定确认'
    }
  },
  {
    path: '/msgList',
    name: 'msgList',
    component: msgList,
    meta: {
      title: '消息列表'
    }
  },
  {
    path: '/auditList',
    name: 'auditList',
    component: auditList,
    meta: {
      title: '我的审核申请'
    }
  },
  {
    path: '/auditHistory',
    name: 'auditHistory',
    component: auditHistory,
    meta: {
      title: '审核记录'
    }
  },
  {
    path: '/auditDetails',
    name: 'auditDetails',
    component: auditDetails,
    meta: {
      title: '审核详情'
    }
  },
  {
    path: '/toAudit',
    name: 'toAudit',
    component: toAudit,
    meta: {
      title: '待我审核'
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  // history: createWebHistory(process.env.BASE_URL),
  routes
})

// 在全局前置守卫中设置标题
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'plusm'
  next()
})

export default router
